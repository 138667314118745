import { fetchData } from './fetchData';
import { fieldRulesList } from './fieldRules';
import {i18n} from "../i18n";

const accounting = {
    install(vue) {

        vue.config.globalProperties.$accounting = {
            edit: async (user_id, profile) => fetchData("post", profile ? "/api/profile" : ("/api/user/" + user_id)).then((resp) => resp),
            profile: async () => fetchData('post', '/api/accounting/self', {}, {}, () => false).then((resp) => resp),
            default: async (domain_id) => fetchData('post', '/api/accounting/domain', {domain: domain_id}, {}, () => false).then((resp) => resp)
        };

        vue.config.globalProperties.$accounting.passwordRules = (policy, isAdmin) => {
            return !policy ?
                fieldRulesList.passwordLowRules :
                fieldRulesList.passwordHighRules
                    .concat([
                        isAdmin
                            ?
                            v => String(v).length >= policy.admin_min_password_length
                                || i18n.global.t('Common.Rules.rule_min_length')
                                    .replace("#1", policy.admin_min_password_length)
                            :
                            v => String(v).length >= policy.min_password_length
                                || i18n.global.t('Common.Rules.rule_min_length')
                                    .replace("#1", policy.min_password_length),

                        v => !fieldRulesList.isRepeating(String(v), policy.allow_repeated_symb, false)
                            || i18n.global.t('Common.Rules.rule_repeating_symbols'),
                        v => !fieldRulesList.isRepeating(String(v), policy.allow_repeated_symb_comb, true)
                            || i18n.global.t('Common.Rules.rule_repeating_combinations'),
                    ])
        }
    },
}

export {accounting};
import { i18n } from "@/i18n";

/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    view: 'Dashboard',
    meta: {
      alias: i18n.global.t('Router.Path.dashboard'),
      requiresAuth: true,
      roles: [2, 3, 4]
    }
  },

  {
    path: '/accounting',
    name: 'accounting',
    view: 'accounting/page',
    meta: {
      alias: i18n.global.t('Router.Path.accounting'),
      requiresAuth: true,
      roles: [1, 2, 3, 4]
    }
  },

  {
    path: '/domain/:domain_id',
    name: 'domain',
    view: 'domains/edit',
    meta: {
      alias: i18n.global.t('Router.Path.domains_edit'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [1]
    }
  },
  {
    path: '/domains/new',
    name: 'domain-new',
    view: 'domains/new',
    meta: {
      alias: i18n.global.t('Router.Path.domains_new'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [1]
    }
  },
  {
    path: '/domain/:domain_id/admins/:user_id',
    name: 'admin',
    view: 'users/profile',
    meta: {
      alias: i18n.global.t('Router.Path.admins_edit'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [1]
    }
  },

  {
    path: '/superuser/:user_id',
    name: 'superuser',
    view: 'users/profile',
    meta: {
      alias: i18n.global.t('Router.Path.superusers_edit'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [1]
    }
  },

  {
    path: '/user/:user_id',
    name: 'user',
    view: 'users/profile',
    meta: {
      alias: i18n.global.t('Router.Path.users_edit'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [1, 2, 3]
    }
  },

  {
    path: '/policy/:policy_id',
    name: 'policy',
    view: 'security/policy',
    meta: {
      alias: i18n.global.t('Router.Path.policy'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [1, 2]
    }
  },

  {
    path: '/:class/task/:task_id',
    name: 'task-rule',
    view: 'settings/task',
    meta: {
      alias: i18n.global.t('Router.Path.rule-new'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [2]
    }
  },

  {
    path: '/notifications/rule/:rule_id',
    name: 'notification-rule',
    view: 'notifications/rule',
    meta: {
      alias: i18n.global.t('Router.Path.rule-new'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [1, 2, 3, 4]
    }
  },
  {
    path: '/notifications/transport/new',
    name: 'transport-new',
    view: 'notifications/transport/new',
    meta: {
      alias: i18n.global.t('Router.Path.transport-new'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [1, 2, 3, 4]
    }
  },
  {
    path: '/notifications/transport/:transport_id/edit',
    name: 'transport-edit',
    view: 'notifications/transport/new',
    meta: {
      alias: i18n.global.t('Router.Path.transport-edit'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [1, 2, 3, 4]
    }
  },

  {
    path: '/logout',
  },
  {
    path: '/login',
    name: 'login',
    view: 'auth/login',
    meta: {
      alias: i18n.global.t('Router.Path.login'),
      requiresAuth: false,
      roles: [1, 2, 3, 4]
    }
  },
  {
    path: '/demo',
    name: 'demo',
    view: 'auth/login',
    meta: {
      alias: i18n.global.t('Router.Path.login'),
      requiresAuth: false,
      roles: [1, 2, 3, 4]
    }
  },
  {
    path: '/profile',
    name: 'profile',
    view: 'users/profile',
    meta: {
      alias: i18n.global.t('Router.Path.profile'),
      requiresAuth: true,
      roles: [1, 2, 3, 4]
    }
  },

  {
    path: '/settings',
    name: 'settings',
    view: 'users/settings',
    meta: {
      alias: i18n.global.t('Router.Path.settings'),
      requiresAuth: true,
      roles: [1, 2, 3, 4]
    }
  },

  {
    path: '/devices/new/:mode',
    name: 'device-new',
    view: 'devices/new',
    meta: {
      alias: i18n.global.t('Router.Path.devices_new'),
      backRoute: 'dashboard',
      requiresAuth: true,
      roles: [2]
    }
  },
  {
    path: '/device/edit/:dev_id',
    name: 'device-edit',
    view: 'devices/edit',
    meta: {
      alias: i18n.global.t('Router.Path.devices_edit'),
      backRoute: 'dashboard',
      requiresAuth: true,
      roles: [2, 3, 4]
    }
  },
  {
    path: '/devices/multiple',
    name: 'device-multiple',
    view: 'devices/multiple',
    meta: {
      alias: i18n.global.t('Router.Path.devices_multiple'),
      backRoute: 'dashboard',
      requiresAuth: true,
      roles: [2, 3, 4]
    }
  },
  {
    path: '/device/:dev_id',
    name: 'device',
    view: 'devices/about',
    meta: {
      alias: i18n.global.t('Router.Path.devices_about'),
      backRoute: 'dashboard',
      requiresAuth: true,
      roles: [2, 3, 4]
    }
  },

  {
    path: '/events',
    name: 'events',
    view: 'events/list',
    meta: {
      alias: i18n.global.t('Router.Path.events'),
      requiresAuth: true,
      roles: [2, 3, 4]
    }
  },

  {
    path: '/scripts',
    name: 'scripts',
    view: 'scripts/list',
    meta: {
      alias: i18n.global.t('Router.Path.scripts'),
      requiresAuth: true,
      roles: [2, 3],
    }
  },

  {
    path: '/templates',
    name: 'templates',
    view: 'templates/list',
    meta: {
      alias: i18n.global.t('Router.Path.templates'),
      requiresAuth: true,
      roles: [2, 3],
    }
  },
  {
    path: '/templates/new',
    name: 'template-new',
    view: 'templates/new',
    meta: {
      alias: i18n.global.t('Router.Path.templates_new'),
      backRoute: 'templates',
      requiresAuth: true,
      roles: [2]
    }
  },
  {
    path: '/template/:temp_id',
    name: 'template',
    view: 'templates/edit',
    meta: {
      alias: i18n.global.t('Router.Path.templates_edit'),
      backRoute: 'templates',
      requiresAuth: true,
      roles: [2, 3]
    }
  },

  {
    path: '/commands',
    name: 'commands',
    view: 'commands/list',
    meta: {
      alias: i18n.global.t('Router.Path.commands'),
      requiresAuth: true,
      roles: [2, 3, 4]
    }
  },
  {
    path: '/commands/new',
    name: 'command-new',
    view: 'commands/new',
    meta: {
      alias: i18n.global.t('Router.Path.commands_new'),
      backRoute: 'commands',
      requiresAuth: true,
      roles: [2, 3]
    }
  },
  {
    path: '/command/:command_id',
    name: 'command',
    view: 'commands/edit',
    meta: {
      alias: i18n.global.t('Router.Path.commands_edit'),
      backRoute: 'commands',
      requiresAuth: true,
      roles: [2, 3, 4]
    }
  },
  {
    path: '/user-command/:command_id',
    name: 'user-command',
    view: 'settings/command',
    meta: {
      alias: i18n.global.t('Router.Path.user_command'),
      backRoute: 'accounting',
      requiresAuth: true,
      roles: [2]
    }
  },
  {
    path: '/report/:device_id/:report_id',
    name: 'report',
    view: 'reports/edit',
    meta: {
      alias: i18n.global.t('Router.Path.report'),
      requiresAuth: true,
      roles: [2, 3, 4]
    }
  },
  {
    path: '/reports/',
    name: 'reports',
    view: 'reports/list',
    meta: {
      alias: i18n.global.t('Router.Path.reports'),
      backRoute: 'reports',
      requiresAuth: true,
      roles: [2, 3, 4]
    }
  },

  {
    path: '/script/:script_id',
    name: 'script',
    view: 'scripts/edit',
    meta: {
      alias: i18n.global.t('Router.Path.scripts_edit'),
      backRoute: 'scripts',
      requiresAuth: true,
      roles: [2, 3]
    }
  },
  {
    path: '/notFound',
    name: 'notFound404',
    view: 'notFound404/notFound404',
    meta: {
      alias: 'Peer',
      backRoute: 'peers',
      requiresAuth: false,
      roles: [1, 2, 3, 4],
    }
  },
  {
    path: '/registry',
    name: 'registry',
    view: 'domains/registry',
    meta: {
      alias: 'Registration',
      backRoute: 'login',
      requestAuth: false,
      roles: [1, 2, 3, 4],
    }
  }
]
